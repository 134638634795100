<template>
  <div class="bg-[#003A40] fixed top-0 left-0 w-full z-20 h-20">
    <div class="py-3 px-6">
      <router-link :to="{ name: 'HomeView' }">
        <img src="Komm-Unity.png" alt="Logo" class="h-14 logo">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header_component',
  data() {},
  methods: {}
}
</script>

<style scoped>
body {
  margin: 0;
  height: 100%;
}

nav a {
  font-weight: bold;
  color: #f4f4f4;
}

.logo {
  user-select: none;
  -webkit-user-drag: none;
}
</style>
